import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Page from '../components/Page'
import SEO from '../components/SEO'
import Schema from '../components/Schema'
import LeadSpot from '../components/solutions/LeadSpot'
import LeadStreamer from '../components/primary/LeadStreamer'
import ExpandableImage from '../components/ExpandableImage'
import DemoVideo from '../components/primary/DemoVideo'
import CallToAction from '../components/primary/CallToAction'
import Newsletter from '../components/Newsletter'

import { isEven } from '../utils/num'

const ImageHubPage = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query ImageHubPageQuery {
      markdownRemark(frontmatter: { path: { eq: "/image-hub" } }) {
        frontmatter {
          title
          description
          path
          lead {
            ...LeadSpotFragment
            ...LeadStreamerFragment
          }
          benefitsYou {
            blurbsTwo {
              title
              text
              image {
                modal: childImageSharp {
                  ...SharpImageModalFragment
                }
                thumb: childImageSharp {
                  fixed(width: 550, quality: 90) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
          ...DemoVideoFragment
          ...CallToActionFragment
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter

  return (
    <Page className="p-solutions p-solutions--manufacturers p-solutions--imageHub has-fingerPrints">
      <SEO title={pageData.title} description={pageData.description} />
      <Schema
        breadcrumbs={[
          {
            name: pageData.title,
            uri: pageData.path,
          },
        ]}
      />

      <LeadSpot
        title={pageData.lead.spot.title}
        header={pageData.lead.spot.header}
        imageFolder="image-hub"
      />

      <LeadStreamer
        id={pageData.lead.streamer.id}
        title={pageData.lead.streamer.title}
        image={pageData.lead.streamer.image}
        imageWidth={pageData.lead.streamer.imageWidth}
        fill={`#f4f4f4`}
      />

      <section
        id="benefits-to-you"
        className="c-section c-section--forBenefits c-section--forBenefits--you"
      >
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-forBenefits">
              {pageData.benefitsYou.blurbsTwo.map((blurb, i) => (
                <div
                  key={blurb.title}
                  className={`c-forBenefitsCard ${
                    !isEven(i) ? 'c-forBenefitsCard--reversed' : ''
                  }`}
                >
                  <div className="c-forBenefitsCard__block c-forBenefitsCard__block--text">
                    <h5 className="c-forBenefitsCard__header">{blurb.title}</h5>
                    <div className="c-forBenefitsCard__text">
                      <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                    </div>
                  </div>

                  <div className="c-forBenefitsCard__block c-forBenefitsCard__block--image">
                    <ExpandableImage
                      imageData={blurb.image.modal}
                      bottomRight={isEven(i)}
                    >
                      <picture>
                        <source
                          type="image/webp"
                          srcSet={blurb.image.thumb.fixed.srcSetWebp}
                        />
                        <img
                          srcSet={blurb.image.thumb.fixed.srcSet}
                          src={blurb.image.thumb.fixed.src}
                          alt={blurb.title}
                          title={blurb.title}
                          width={blurb.image.thumb.fixed.width}
                          height={blurb.image.thumb.fixed.height}
                          loading="lazy"
                          className="c-forBenefitsCard__image img-fluid"
                        />
                      </picture>
                    </ExpandableImage>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <DemoVideo
        header={pageData.demoVideo.header}
        blurb={pageData.demoVideo.blurb}
        buttonText={pageData.demoVideo.buttonText}
        buttonLink={pageData.demoVideo.buttonLink}
        videoUrl={pageData.demoVideo.videoUrl}
      />

      <CallToAction ctaData={pageData.signUpCta} fill={`#f4f4f4`} />

      <Newsletter />
    </Page>
  )
}

export default ImageHubPage
